import React from 'react'
import './portfolio.css'
import Image1 from '../../assets/rocket_elevator.jpg'
import Image2 from '../../assets/react-mobile.jpg'
import Image3 from '../../assets/nft_project.jpg'
import Image4 from '../../assets/real_estate.jpg'
import Image5 from '../../assets/portfolio.jpg'

import { AiFillGithub } from 'react-icons/ai'


const Portfolio = () => {
    return (
        <section className='portfolio__container' id='portfolio' >
            <h1><span>&#60; Portfolio &#8725; &#62;</span></h1>
            <div className='portfolio__wrapper'>
                <article className='portfolio-item'>
                    <h3>Rocket Elevator information system</h3><br />
                    <p>This is a fictive project that I did in my bootcamp.
                        This web application have a frontend client site and a back office for the employee of the company.
                        This application is for a fictive company name Rocket Elevators who provided
                        the installation and maintenance of elevators in various location in America. <br />
                        This is the main features:<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&#9679; Login validation access to the back office<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&#9679; Database on AWS <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&#9679; Integration of different API:<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9725; Zendesk &#9725; Twillow &#9725; Chatbot<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&#9679; Quotes form with JavaScript and jQuery<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&#9679; Intervention form with JavaScript and jQuery</p>
                    <div className='img-container'>
                        <img src={Image1} alt='' />
                    </div>
                    <div className='btn-container'>
                        <a href='https://github.com/KenH2021/Rocket-Elevators-Foundation' type='button'
                            className='btn' target='_blank'><AiFillGithub />&nbsp;Code</a>
                    </div>
                </article>
                <article className='portfolio-item'>
                    <h3>Rocket Elevator Mobile App</h3><br />
                    <p>This is a mobile application made with ReactNative and Expo.
                        This application main objective is to be use for the elevator’s
                        technician when servicing elevators. <br />
                        This is the main features: <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&#9679; Login validation with a employee email<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&#9679; List of all elevators on the database<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&#9679; Clickable elevators list who are ‘Offline’<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&#9679; Change the status to a elevator to ‘Online’<br /></p>
                    <div className='img-container'>
                        <img src={Image2} alt='' />
                    </div>
                    <div className='btn-container'>
                        <a href='https://github.com/KenH2021/RocketApp_mobile' type='button'
                            className='btn' target='_blank'><AiFillGithub />&nbsp;Code</a>
                    </div>
                </article>
                <article className='portfolio-item'>
                    <h3>Rocket Elevator Mining App</h3><br />
                    <p>This is a web application use where you can buy a specific NFT created by me.
                        This is the main features: <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&#9679; Buy a specific NFT on Opensea via the application<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&#9679; Creation of a new token name RocketTocken<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&#9679; Connecting automatically to your MetaMask Wallet<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&#9679; Create a contract for the NFT</p>
                    <div className='img-container'>
                        <img src={Image3} alt='' />
                    </div>
                    <div className='btn-container'>
                        <a href='https://github.com/KenH2021/Rocket_Elevators_Blockchain' type='button'
                            className='btn' target='_blank'><AiFillGithub />&nbsp;Code</a>
                        <a href='https://sheltered-mesa-22736.herokuapp.com/' type='button'
                            className='btn-secondary' target='_blank'>Live Demo</a>
                    </div>
                </article>
                <article className='portfolio-item'>
                    <h3>Real Estate Agency Website</h3><br />
                    <p>This is a web application for a fictive real estate agency with a client-side where we can show the different
                        listing related to a specific realtor. There’s also a search page where we can filter and search specific aspect
                        of listings by keyword like pool.  <br />
                        This is the main features:<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&#9679; Login with the right credential as a realtor<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&#9679; Create, Modify, Delete Listing and Realtor</p>
                    <div className='img-container'>
                        <img src={Image4} alt='' />
                    </div>
                    <div className='btn-container'>
                        <a href='https://github.com/KenH2021/RealEstateProjet-Django' type='button'
                            className='btn' target='_blank'><AiFillGithub />&nbsp;Code</a>
                    </div>
                </article>
                <article className='portfolio-item'>
                    <h3>My Portfolio</h3><br />
                    <p>This is my website that I did with React Native, CSS and HTML.
                        This site is use as my portfolio in my job search. This site is 100% responsive.<br />
                        This is the main features:<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&#9679; 100% responsive with flex box<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&#9679; 2 navigation<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&#9679; Link to my repositories<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&#9679; Downloadable Cv as a pdf file
                    </p>
                    <div className='img-container'>
                        <img src={Image5} alt='' />
                    </div>
                    <div className='btn-container'>
                        <a href='https://github.com/KenH2021/portfolio_website' type='button'
                            className='btn' target='_blank'><AiFillGithub />&nbsp;Code</a>
                        <a href='https://kenherisse.com' type='button'
                            className='btn-secondary' target='_blank'>Live Demo</a>
                    </div>
                </article>
                <div className='divider'>/*---------------------------------------------------------------------------------------------------*/</div>
            </div>
        </section>
    )
}

export default Portfolio