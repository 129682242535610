import React from 'react'
import './header.css'

const Header = () => {
    return (
        <section className='header__container' id='header'>
            <div className='wrapper'>
                <h1 className='typed-out'><span>&#60; My name is Ken Herisse  &#8725; &#62;</span></h1>
            </div>
            <h2>I'm a Web Developper</h2>
            <h4>Through my coding formation, I worked with different programming languages such as
                Python, Node js, C#, Ruby Rails etc..
                I found a great value working with my teamates trying to solve problems.
                Working in the printing field as taught me to be a creative mind,
                a multi tasking person and a meticulous employee.<br />
                I’m focusing specifically on Object Oriented Program such as Python.<br />
                This is my stacks:<br />
                <div className='stack'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&#9679; Python &nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&#9679; Django &nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&#9679; GitHub &nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&#9679; React &nbsp;<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9679; Postgres &nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&#9679; MySQL &nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&#9679; Javacript &nbsp;
                </div>
            </h4>

            <div className='divider'>/*---------------------------------------------------------------------------------------------------*/</div>
        </section>
    )
}

export default Header