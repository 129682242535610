import Header from './components/header/Header'
import Navigator from './components/navigator/Navigator'
import Portfolio from './components/portfolio/Portfolio'
import Contact from './components/contact-me/Contact'

function App() {
  return (
    <div>
      <Header />
      <Navigator />
      <Portfolio />
      <Contact />
    </div>
  );
}

export default App;
