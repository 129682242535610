import React from 'react'
import './navigator.css'
import { RiHomeLine } from 'react-icons/ri';
import { GoGithubAction } from 'react-icons/go';
import { AiOutlineMail } from 'react-icons/ai';


const Navigator = () => {
    return (
        <nav className='navbar nav-container'>
            <a className="logo">&#60; &#8725; &#62;
                <span className='link-text'> </span>
            </a>
            <a href='#header' className="nav-link"><RiHomeLine />
                <span className='link-text'>&nbsp;Home </span>
            </a>
            <a href='#portfolio' className="nav-link"><GoGithubAction />
                <span className='link-text'>&nbsp;Portfolio </span>
            </a>
            <a href='#contact' className="nav-link"><AiOutlineMail />
                <span className='link-text'>&nbsp;Contact </span>
            </a>
            <a className="trademark">
                <span className='link-text'>@KenHerisse2022</span>
            </a>
        </nav>
    )
}

export default Navigator