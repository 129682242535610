import React from 'react'
import '../../components/contact-me/contact.css'
import { AiTwotoneMail } from 'react-icons/ai'
import { BsPhone } from 'react-icons/bs'
import { AiFillGithub } from 'react-icons/ai'
import { AiOutlineLinkedin } from 'react-icons/ai'
import { useRef } from 'react';
import emailjs from 'emailjs-com';
import MyPDF from '../../assets/KenHerisse_CV.pdf'




const Contact = () => {

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_7botfvo', 'template_kew4lge', form.current, 'YZGHUyUjknGokdMJB')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });

    e.target.reset();
  };

  return (
    <section className='container__contact' id='contact'>
      <h1><span>&#60; Contact-Me  &#8725; &#62;</span></h1>
      <div className='container-flex'>
        <div className='container-info'>
          <h2 className='info'><span>&#60; My Info  &#8725; &#62;</span></h2>
          <a href='mailto:h_ken01@hotmail.com' target="_blank"><AiTwotoneMail />&nbsp; h_ken01@hotmail.com</a><br />
          <a href='https://github.com/KenH2021' target="_blank"><AiFillGithub />&nbsp; github.com/KenH2021</a><br />
          <a href='https://www.linkedin.com/in/ken-herisse/' target="_blank"><AiOutlineLinkedin />&nbsp; linkedin.com/in/ken-herisse</a><br />
          <p><BsPhone />&nbsp; 514.839.5069</p><br />
          <a href={MyPDF} download="KenHerisse_CV.pdf" className='btn-cv'> View CV / Download PDF</a>
        </div>
        <div className='container-query'>
          <h2 className='query'><span>&#60; Contact Query  &#8725; &#62;</span></h2>

          <form ref={form} onSubmit={sendEmail} className='form_query'>
            <input type="text" placeholder="First name" id="fname" name="fname" /><br />
            <input type="text" id="lname" placeholder="Last name" name="lname" /><br />
            <input type="email" id="email" placeholder="Email" name="email" /><br />
            <input type="text" id="telephone" placeholder="Telephone" name="telephone" /><br />
            <input type="message" id="message" placeholder="Your message" name="message" /><br />
            <button type='submit' className='btn-submit'>Submit</button>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Contact